import styled from "styled-components";
export const ErrorMessage = styled.div `
  color: var(--danger-bright);
  text-align: center;
  font-size: 14px;
`;
export const ErrorContainer = styled.div `
  width: 100%;
  font-family: Rubik;
  font-size: 14px;
  color: #ff5555;
`;
