import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getEmailAddress } from "@pcd/email-pcd";
import { styled } from "@pcd/passport-ui";
export const EmailPCDUI = {
    renderCardBody: EmailCardBody
};
function EmailCardBody({ pcd }) {
    const emailAddress = getEmailAddress(pcd);
    if (!emailAddress)
        return _jsx(_Fragment, {});
    const [username, domain] = emailAddress.split("@");
    return (_jsx(Container, { children: _jsx(EmailInfo, { children: _jsxs(EmailWrapper, { children: [_jsx(Username, { children: username }), _jsxs(Domain, { children: ["@", domain] }), _jsx("div", { style: { clear: "both" } })] }) }) }));
}
const Container = styled.span `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
const EmailInfo = styled.div `
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const EmailWrapper = styled.div `
  display: flex;
  max-width: 80%;
  overflow: hidden;
`;
const Username = styled.span `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  min-width: 0;
`;
const Domain = styled.span `
  flex-shrink: 0;
  margin-left: -1px;
`;
