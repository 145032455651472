import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldLabel, HiddenText, Separator, Spacer, TextContainer, styled } from "@pcd/passport-ui";
export const SemaphoreSignaturePCDUI = {
    renderCardBody: SemaphoreSignatureCardBody
};
function SemaphoreSignatureCardBody({ pcd }) {
    return (_jsxs(Container, { children: [_jsx("p", { children: "This PCD represents a particular message that has been signed by a particular Semaphore Identity." }), _jsx(Separator, {}), _jsx(FieldLabel, { children: "Commitment" }), _jsx(HiddenText, { text: pcd.claim.identityCommitment }), _jsx(Spacer, { h: 8 }), _jsx(FieldLabel, { children: "Signed Message" }), _jsx(TextContainer, { children: pcd.claim.signedMessage })] }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
