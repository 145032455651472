import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FieldLabel, Separator, styled } from "@pcd/passport-ui";
import { getErrorMessage } from "@pcd/util";
import { useState } from "react";
export const UnknownPCDUI = {
    renderCardBody: UnknownPCDCardBody
};
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
const SerializedPCDContainer = styled.div `
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow-wrap: anywhere;
  max-height: 300px;
  overflow-y: auto;
`;
export const StyledLinkButton = styled.a `
  color: #e6a50f;
  cursor: pointer;
  text-decoration: none;
`;
/**
 * This component renders the body of a 'Card' that Zupass uses to display PCDs to the user.
 */
function UnknownPCDCardBody({ pcd }) {
    const [showDetails, setShowDetails] = useState(false);
    return (_jsxs(Container, { children: [_jsxs("p", { children: ["Something went wrong reading this ", pcd.claim.serializedPCD.type, "."] }), !!pcd.claim.error && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx("p", { children: _jsx("b", { children: getErrorMessage(pcd.claim.error) }) })] })), _jsx("br", {}), _jsx("p", { children: "Try refreshing the page, or contact Zupass support if the problem persists." }), _jsx("p", { children: _jsxs(StyledLinkButton, { onClick: () => setShowDetails(!showDetails), children: [showDetails ? "Hide" : "View", " details"] }) }), !!showDetails && _jsx(UnknownPCDDetails, { pcd: pcd })] }));
}
function UnknownPCDDetails({ pcd }) {
    return (_jsxs(_Fragment, { children: [_jsx(Separator, {}), _jsx(FieldLabel, { children: "PCD Type" }), _jsx("pre", { children: pcd.claim.serializedPCD.type }), _jsx(FieldLabel, { children: "Error" }), _jsx("pre", { style: { overflowX: "auto" }, children: pcd.claim.error?.toString() || getErrorMessage(pcd.claim.error) }), _jsx(FieldLabel, { children: "Serialized Content" }), _jsx(SerializedPCDContainer, { children: pcd.claim.serializedPCD.pcd })] }));
}
