import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldLabel, Separator, Spacer, TextContainer, styled } from "@pcd/passport-ui";
export const SemaphoreGroupPCDUI = {
    renderCardBody: SemaphoreGroupCardBody
};
function SemaphoreGroupCardBody({ pcd }) {
    return (_jsxs(Container, { children: [_jsx("p", { children: "This PCD represents a group signal in the context of a Semaphore Protocol group. In other words, this is a ZK proof that a particular external nullifier sent a particular signal and belongs to a particular merkle tree identified by the merkle root." }), _jsx(Separator, {}), _jsx(FieldLabel, { children: "Group Root" }), _jsx(TextContainer, { children: pcd.claim.merkleRoot }), _jsx(Spacer, { h: 8 }), _jsx(FieldLabel, { children: "Signal" }), _jsx(TextContainer, { children: pcd.claim.signal }), _jsx(Spacer, { h: 8 }), _jsx(FieldLabel, { children: "Nullifier Hash" }), _jsx(TextContainer, { children: pcd.claim.nullifierHash }), _jsx(Spacer, { h: 8 }), _jsx(FieldLabel, { children: "External Nullifier" }), _jsx(TextContainer, { children: pcd.claim.externalNullifier })] }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
