import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CardWithCopy, HiddenText, styled } from "@pcd/passport-ui";
export const SemaphoreIdentityPCDUI = {
    renderCardBody: SemaphoreIdentityCardBody
};
function SemaphoreIdentityCardBody({ pcd }) {
    return (_jsxs(Container, { children: [_jsx("p", { style: { color: "var(--text-primary, #1E2C50)", marginBottom: 10 }, children: "This PCD represents a Sempahore Identity, which can be used to send anonymous group signals, log into websites, etc." }), _jsx(CardWithCopy, { title: "Semaphore Commitment", onCopy: () => {
                    return navigator.clipboard.writeText(pcd.claim.identityV3.commitment.toString());
                }, children: _jsx(HiddenText, { text: pcd.claim.identityV3.commitment.toString() }) })] }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
