import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@pcd/passport-ui";
import Markdown from "react-markdown";
export const MessagePCDUI = {
    renderCardBody: EdDSAMessageCardBody
};
export function EdDSAMessageCardBody({ pcd }) {
    return (_jsx(Container, { children: pcd.claim.mdBody ? (_jsx("div", { style: { textAlign: "center" }, children: _jsx(Markdown, { children: pcd.claim.mdBody }) })) : (_jsx("div", { style: { opacity: 0.5 } })) }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;

  p {
  }

  img {
    margin-top: 8px;
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;
  }
`;
