import { jsx as _jsx } from "react/jsx-runtime";
import { getQRCodeColorOverride, linkToTicket } from "@pcd/eddsa-ticket-pcd";
import { QRDisplayWithRegenerateAndStorage } from "@pcd/passport-ui";
import { useCallback } from "react";
export function TicketQR({ pcd, idBasedVerifyURL }) {
    const generate = useCallback(async () => {
        if (pcd.claim.ticket.ticketSecret) {
            return pcd.claim.ticket.ticketSecret;
        }
        else {
            return linkToTicket(idBasedVerifyURL ?? "", pcd.claim.ticket.ticketId, pcd.claim.ticket.eventId);
        }
    }, [idBasedVerifyURL, pcd]);
    return (_jsx(QRDisplayWithRegenerateAndStorage, { generateQRPayload: generate, maxAgeMs: 1000 * 60, uniqueId: undefined, fgColor: getQRCodeColorOverride(pcd) }, pcd.id));
}
