import { jsx as _jsx } from "react/jsx-runtime";
import mediumZoom from "medium-zoom";
import { useCallback, useRef } from "react";
export function ImageZoom({ options, ...props }) {
    const zoomRef = useRef(null);
    const getZoom = useCallback(() => {
        if (zoomRef.current === null) {
            zoomRef.current = mediumZoom(options);
        }
        return zoomRef.current;
    }, [options]);
    const attachZoom = useCallback((image) => {
        const zoom = getZoom();
        if (image) {
            zoom.attach(image);
        }
        else {
            zoom.detach();
        }
    }, [getZoom]);
    return _jsx("img", { ...props, ref: attachZoom });
}
