import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QRDisplayWithRegenerateAndStorage, encodeQRPayload, styled } from "@pcd/passport-ui";
import { RSATicketPCDPackage, getQRCodeColorOverride, getTicketData, initArgs } from "@pcd/rsa-ticket-pcd";
import { useCallback } from "react";
export const RSATicketPCDUI = {
    renderCardBody: RSATicketCardBody
};
function RSATicketCardBody({ pcd }) {
    const ticketData = getTicketData(pcd);
    return (_jsxs(Container, { children: [_jsx(TicketQR, { pcd: pcd }), _jsxs(TicketInfo, { children: [_jsx("span", { children: ticketData.attendeeName }), _jsx("span", { children: ticketData.attendeeEmail })] })] }));
}
function TicketQR({ pcd }) {
    const generate = useCallback(async () => {
        const serialized = await RSATicketPCDPackage.serialize(pcd);
        const serializedPCD = JSON.stringify(serialized);
        const encodedPCD = encodeQRPayload(serializedPCD);
        if (!initArgs.makeEncodedVerifyLink) {
            throw new Error("must provide makeEncodedVerifyLink");
        }
        const verificationLink = initArgs.makeEncodedVerifyLink(encodedPCD);
        return verificationLink;
    }, [pcd]);
    return (_jsx(QRDisplayWithRegenerateAndStorage, { generateQRPayload: generate, maxAgeMs: 1000 * 60, uniqueId: pcd.id, fgColor: getQRCodeColorOverride(pcd) }));
}
const Container = styled.span `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
const TicketInfo = styled.div `
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
