import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@pcd/passport-ui";
export const RSAImagePCDUI = {
    renderCardBody: RSAImageCardBody
};
export function RSAImageCardBody({ pcd }) {
    const imageData = JSON.parse(pcd.proof.rsaPCD.claim.message);
    return (_jsx(Container, { children: _jsx("img", { src: imageData.url }) }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;

  img {
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;
  }
`;
