import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import styled from "styled-components";
const Label = styled.label `
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
const Switch = styled.div `
  position: relative;
  width: 60px;
  height: 28px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 35px;
    top: 50%;
    left: 2px;
    background: white;
    transform: translate(0, -50%);
  }
`;
const Input = styled.input `
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: green;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;
/**
 * A simple toggle-switch React component. Internally implemented as a
 * checkbox, but rendered as an animated sliding toggle.
 */
export const ToggleSwitch = ({ label, checked, onChange }) => {
    const handleChange = useCallback(() => {
        onChange();
    }, [onChange]);
    return (_jsxs(Label, { children: [_jsx("span", { children: label }), _jsx(Input, { checked: checked, type: "checkbox", onChange: handleChange }), _jsx(Switch, {})] }));
};
