import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldLabel, HiddenText, Separator, Spacer, styled } from "@pcd/passport-ui";
export const RSAPCDUI = {
    renderCardBody: RSACardBody
};
export function RSACardBody({ pcd }) {
    return (_jsxs(Container, { children: [_jsx("p", { children: "This PCD represents an RSA signature of some text" }), _jsx(Separator, {}), _jsx(FieldLabel, { children: "Signed Message" }), _jsx(HiddenText, { text: pcd.claim.message }), _jsx(Spacer, { h: 8 }), _jsx(FieldLabel, { children: "RSA Public Key" }), _jsx(HiddenText, { text: pcd.proof.publicKey })] }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
