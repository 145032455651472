import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldLabel, HiddenText, Separator, Spacer, styled } from "@pcd/passport-ui";
export const EdDSAPCDUI = {
    renderCardBody: EdDSACardBody
};
/**
 * This component renders the body of a 'Card' that Zupass uses to display PCDs to the user.
 */
function EdDSACardBody({ pcd }) {
    return (_jsxs(Container, { children: [_jsx("p", { children: "This PCD represents an EdDSA signature of some bigint values" }), _jsx(Separator, {}), _jsx(FieldLabel, { children: "Signed Message" }), _jsx(HiddenText, { text: pcd.claim.message.map((num) => num.toString()).join(",") }), _jsx(Spacer, { h: 8 }), _jsx(FieldLabel, { children: "EdDSA Public Key" }), _jsx(HiddenText, { text: pcd.claim.publicKey.join(",") })] }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
