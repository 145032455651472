import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardWithCopy, HiddenText, styled, TextContainer } from "@pcd/passport-ui";
import { podEntriesToJSON } from "@pcd/pod";
const StyledPre = styled.pre `
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
`;
export function DefaultPODPCDCardBody({ pcd }) {
    return (_jsx("div", { style: { display: "flex", gap: 8, flexDirection: "column" }, children: _jsxs(CardWithCopy, { title: "", onCopy: () => {
                return navigator.clipboard.writeText(JSON.stringify(pcd.pod.toJSON(), null, 2));
            }, children: [_jsx(TextContainer, { style: {
                        overflow: "auto",
                        maxHeight: "280px",
                        backgroundColor: "#F6F8FD"
                    }, children: _jsx(StyledPre, { children: JSON.stringify(podEntriesToJSON(pcd.claim.entries), null, 2) }) }), _jsx(Card, { title: "EdDSA PUBLIC KEY", children: _jsx(HiddenText, { text: pcd.claim.signerPublicKey, style: { overflowX: "auto" } }) }), _jsx(Card, { title: "EdDSA SIGNATURE", children: _jsx(HiddenText, { text: pcd.proof.signature, style: { overflowX: "auto" } }) })] }) }));
}
