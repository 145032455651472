import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldLabel, HiddenText, Separator, Spacer, TextContainer, styled } from "@pcd/passport-ui";
import { SemaphoreSignaturePCDPackage } from "@pcd/semaphore-signature-pcd";
import { useEffect, useState } from "react";
export const EthereumOwnershipPCDUI = {
    renderCardBody: EthereumOwnershipCardBody
};
function EthereumOwnershipCardBody({ pcd }) {
    const [identityCommitment, setIdentityCommitment] = useState("<deserializing>");
    useEffect(() => {
        SemaphoreSignaturePCDPackage.deserialize(pcd.proof.signatureProof.pcd).then((pcd) => {
            setIdentityCommitment(pcd.claim.identityCommitment);
        });
    }, [pcd]);
    return (_jsxs(Container, { children: [_jsx("p", { children: "This PCD represents that a particular Semphore Identity owns a particular Ethereum Address." }), _jsx(Separator, {}), _jsx(FieldLabel, { children: "Commitment" }), _jsx(HiddenText, { text: identityCommitment }), _jsx(Spacer, { h: 8 }), _jsx(FieldLabel, { children: "Ethereum Address" }), _jsx(TextContainer, { children: pcd.claim.ethereumAddress })] }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
`;
