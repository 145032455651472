import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { styled } from "@pcd/passport-ui";
import { getDescriptionEntry, getImageUrlEntry, getTitleEntry } from "@pcd/pod-pcd";
const CardImg = styled.img `
  border-radius: 8px;
`;
const CardTitle = styled.p `
  color: var(--text-primary, #1e2c50);
  text-transform: uppercase;
  padding-top: 4px;
  font-family: Barlow;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
`;
const CardDescription = styled.p `
  color: var(--text-primary, #1e2c50);

  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;
export function CollectablePODPCDCardBody({ pcd }) {
    const parts = [];
    const imageUrlEntry = getImageUrlEntry(pcd);
    if (imageUrlEntry?.type === "string") {
        parts.push(_jsx(CardImg, { src: imageUrlEntry.value }));
    }
    const detailsParts = [];
    // should we change this to use getDisplayOptions?
    const titleEntry = getTitleEntry(pcd);
    if (titleEntry?.type === "string") {
        detailsParts.push(_jsx(CardTitle, { children: titleEntry.value }));
    }
    const descriptionEntry = getDescriptionEntry(pcd);
    if (descriptionEntry?.type === "string") {
        detailsParts.push(_jsx(CardDescription, { children: descriptionEntry.value }));
    }
    if (detailsParts.length > 0) {
        parts.push(_jsx("div", { style: { paddingLeft: 12 }, children: detailsParts }));
    }
    if (parts.length === 0) {
        parts.push(_jsx("p", { children: "No content" }));
    }
    return _jsx(_Fragment, { children: parts });
}
