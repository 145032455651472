import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Biome, EdDSAFrogPCDPackage, Rarity, Temperament, frogRarityToScore, getEdDSAFrogData } from "@pcd/eddsa-frog-pcd";
import { ImageZoom, StyledLinkButton, encodeQRPayload, styled } from "@pcd/passport-ui";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
export const EdDSAFrogPCDUI = {
    renderCardBody: EdDSAFrogCardBody,
    getHeader: Header
};
function Header({ pcd }) {
    const frogData = useMemo(() => getEdDSAFrogData(pcd), [pcd]);
    if (!frogData) {
        return _jsx(_Fragment, { children: "Frog" });
    }
    if (frogData.rarity === Rarity.Mythic) {
        return (_jsx(MythicContainer, { children: `#${frogData.frogId} ${frogData.name}` }));
    }
    const rarityColors = {
        [Rarity.Common]: "#2D9061",
        [Rarity.Rare]: "#1197B5",
        [Rarity.Epic]: "#6F3BB0",
        [Rarity.Legendary]: "#FF9900",
        [Rarity.Unknown]: "#A7967E",
        [Rarity.Object]: "#A7967E"
    };
    return (_jsx(HeaderContainer, { style: {
            backgroundColor: rarityColors[frogData.rarity] ?? "var(--bg-dark-primary)"
        }, children: `#${frogData.frogId} ${frogData.name}` }));
}
function EdDSAFrogCardBody({ pcd, deletePcd }) {
    const frogData = useMemo(() => getEdDSAFrogData(pcd), [pcd]);
    const [showMore, setShowMore] = useState(false);
    const [showPCD, setShowPCD] = useState(false);
    if (!frogData) {
        return (_jsx(Container, { children: _jsx(FrogQR, { pcd: pcd }) }));
    }
    return showPCD ? (_jsxs(Container, { children: [_jsx(StyledLinkButton, { onClick: () => setShowPCD(false), children: "View as frog" }), _jsx(FrogQR, { pcd: pcd }), _jsx(CopyFrogPCD, { pcd: pcd })] })) : (_jsxs(Container, { children: [_jsx(StyledLinkButton, { style: { textAlign: "center" }, onClick: () => setShowPCD(true), children: "View as proof\u2011carrying data" }), _jsx(ImageZoom, { src: frogData?.imageUrl, draggable: false, loading: "lazy", style: { width: "100%", height: "auto", zIndex: "1000" }, options: {
                    background: "rgba(0, 0, 0, 0.5)"
                } }), _jsxs(FrogInfo, { children: [_jsx(FrogAttribute, { label: "JMP", title: "Jump", value: frogData.jump }), _jsx(FrogAttribute, { label: "VIB", title: "Vibe", value: temperamentValue(frogData.temperament) }), _jsx(FrogAttribute, { label: "SPD", title: "Speed", value: frogData?.speed }), _jsx(FrogAttribute, { label: "INT", title: "Intelligence", value: frogData.intelligence }), _jsx(FrogAttribute, { label: "BTY", title: "Beauty", value: frogData.beauty }), frogData.timestampSigned >
                        new Date("2024-03-02T11:29:46.795Z").getTime() && (_jsx(FrogAttribute, { label: "EXP", title: "Beauty", value: frogRarityToScore(frogData.rarity) }))] }), _jsxs("div", { children: [_jsx(StyledLinkButton, { onClick: () => setShowMore(!showMore), children: showMore ? "Collapse" : "See more" }), deletePcd && (_jsx(StyledLinkButton, { style: { color: "gray", marginLeft: 15 }, onClick: () => deletePcd(), children: "Delete" }))] }), showMore && (_jsxs(_Fragment, { children: [_jsx(Description, { children: frogData.description }), _jsxs(FrogInfo, { children: [_jsx(FrogAttribute, { label: "Signed at", title: `Signed at: ${frogData.timestampSigned}`, value: new Date(frogData.timestampSigned).toLocaleDateString() }), _jsx(FrogAttribute, { label: "Source", title: "Biome", value: biomeValue(frogData.biome) })] })] }))] }));
}
function FrogAttribute({ label, title, value }) {
    return (_jsxs(Attribute, { children: [_jsx(AttrTitle, { title: title, children: label }), _jsx(AttrValue, { style: { color: attrColor(value) }, children: formatAttrValue(value) })] }));
}
function attrColor(value) {
    if (typeof value === "number") {
        if (value <= 3) {
            return "#a95940";
        }
        if (value >= 7) {
            return "#206b5e";
        }
    }
}
function formatAttrValue(value) {
    if (typeof value === "number") {
        return String(value).padStart(2, "0");
    }
    return value;
}
function temperamentValue(temperament) {
    switch (temperament) {
        case Temperament.UNKNOWN:
            return "???";
        case Temperament.N_A:
            return "N/A";
        default:
            return Temperament[temperament];
    }
}
function biomeValue(biome) {
    return _.startCase(Biome[biome]);
}
function FrogQR({ pcd }) {
    const [hex, setHex] = useState("");
    const generate = useCallback(async () => {
        const serialized = await EdDSAFrogPCDPackage.serialize(pcd);
        return encodeQRPayload(JSON.stringify(serialized));
    }, [pcd]);
    useEffect(() => {
        generate().then(setHex);
    }, [generate]);
    return _jsx(HexContainer, { children: hex });
}
function CopyFrogPCD({ pcd }) {
    const [copied, setCopied] = useState(false);
    const onClick = useCallback(async () => {
        const serialized = await EdDSAFrogPCDPackage.serialize(pcd);
        navigator.clipboard.writeText(JSON.stringify(serialized));
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    }, [pcd]);
    return (_jsx(StyledLinkButton, { onClick: onClick, children: copied ? "Copied!" : "Copy frog PCD" }));
}
const Container = styled.div `
  padding: 16px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;
const FrogInfo = styled.div `
  display: flex;
  justify-content: space-around;
  gap: 20px;
`;
const Description = styled.div `
  font-size: 14px;
  color: rgba(var(--black-rgb), 0.8);
`;
const Attribute = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-family: monospace;
`;
const AttrTitle = styled.div `
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
`;
const AttrValue = styled.div `
  font-size: 14px;
`;
const HexContainer = styled.div `
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow-wrap: anywhere;
  max-height: 300px;
  overflow-y: auto;
`;
const HeaderContainer = styled.div `
  text-align: center;
  margin: -10px;
  padding: 10px;
  color: white;
  font-weight: bold;
`;
const MythicContainer = styled(HeaderContainer) `
  @keyframes SAHGlowingGradient {
    0% {
      background-position: 84% 0;
    }
    50% {
      background-position: 17% 100%;
    }
    100% {
      background-position: 84% 0;
    }
  }

  appearance: none;
  background: linear-gradient(
    48deg,
    #e3c1f4,
    #d9d7ed,
    #dff7f1,
    #acf0ff,
    #e3c1f4,
    #d9d7ed,
    #dff7f1,
    #acf0ff,
    #e3c1f4
  );
  animation: SAHGlowingGradient 8s ease infinite;
  opacity: 1;
  background-size: 400% 400%;

  color: #fda7a7;
  text-shadow: 0px 1px 2px #129191;
`;
